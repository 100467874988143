// libs
import { Formik } from "formik";
import * as React from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { RouteComponentProps } from "react-router";

// components
import { Page, Spinner, withAuthProps, withCommonProps, PermissionDeniedPage, FailureRetryPage } from "../Common";

// state
import { ApplicationState } from "../../store";
import { CommonState } from "../../store/Common/state";
import { IAuthProps } from "../../store/authTypes";
import { RequestState } from "../../store/sharedTypes";
import { validationSchemaCreators } from "../../utils";
import { actionCreators } from "../../store/PrivateLabels/PrivateLabelEdit/actionCreators";
import { PrivateLabelEditState, PrivateLabelEditableData } from "../../store/PrivateLabels/PrivateLabelEdit/state";

type PrivateLabelEditProps =
    PrivateLabelEditState &
    CommonState &
    typeof actionCreators &
    IAuthProps &
    RouteComponentProps<{ privateLabelId: string }>;

const PrivateLabelEditPage = (props: PrivateLabelEditProps) => {
    const pageTitle = "Edit Private Label";
    const privateLabelId = props.match.params.privateLabelId;
    const { resetEditRequestStates } = props;

    React.useEffect(() => {
        return () => {
            // Anything in here is fired on component unmount.
            resetEditRequestStates();
        };
    }, [resetEditRequestStates]);

    if (props.getRequestState === RequestState.NotStarted) {
        props.getPrivateLabelById(privateLabelId);
    }

    if (props.getRequestState === RequestState.InProgress || props.getRequestState === RequestState.NotStarted) {
        return (
            <Page title={pageTitle}>
                <Spinner />
            </Page>
        );
    }

    if (props.getRequestState === RequestState.Failed) {
        return <FailureRetryPage
            pageTitle={pageTitle}
            description="An error occurred when loading the private label."
            onRetry={() => props.getPrivateLabelById(privateLabelId)} />;
    }

    if (!props.isAdmin) {
        return <PermissionDeniedPage pageTitle={pageTitle} history={props.history} />;
    }

    type FormValues = PrivateLabelEditableData & {
        name: string | undefined;
    };

    const emptyValues: FormValues = {
        name: "",
        displayName: "",
        email: "",
        appIdentifier: undefined,
        googlePlayUri: undefined,
        appStoreUri: undefined,
        notificationTitle: undefined
    };

    const initialValues: FormValues = {
        ...emptyValues,
        ...{
            name: props.existingPrivateLabel!.name,
            displayName: props.existingPrivateLabel!.displayName,
            email: props.existingPrivateLabel!.email,
            appIdentifier: props.existingPrivateLabel!.appIdentifier,
            googlePlayUri: props.existingPrivateLabel!.googlePlayUri,
            appStoreUri: props.existingPrivateLabel!.appStoreUri,
            notificationTitle: props.existingPrivateLabel!.notificationTitle
        }
    };

    const validationSchema = Yup.object().shape<FormValues>({
        name: Yup.string(), // <-- no validation here, it's readonly
        displayName: validationSchemaCreators.privateLabelDisplayNameSchema(),
        email: validationSchemaCreators.emailSchema(),
        appIdentifier: validationSchemaCreators.privateLabelAppIdentifierSchema(),
        appStoreUri: validationSchemaCreators.privateLabelAppUriSchema(),
        googlePlayUri: validationSchemaCreators.privateLabelAppUriSchema(),
        notificationTitle: validationSchemaCreators.privateLabelNotificationTitleSchema()
    });

    const getInputClassNames = (isInvalid: boolean) => {
        const classNames = "form-control";
        return isInvalid ? classNames + " is-invalid text-break" : classNames;
    };

    const submit = (values: typeof emptyValues) => {
        props.updatePrivateLabel(privateLabelId, { ...values, appIdentifier: values.appIdentifier || undefined, notificationTitle: values.notificationTitle || undefined });
    };

    return (
        <Page title={pageTitle} id="edit-private-label-page">
            <div className="row">
                <div className="col">
                    <Formik
                        enableReinitialize={true}
                        onSubmit={submit}
                        validateOnChange
                        validationSchema={validationSchema}
                        initialValues={initialValues}>
                        {({ values, dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, setFieldTouched }) =>
                            <form onSubmit={e => e.preventDefault()}>
                                {/* Name */}
                                <div className="form-group">
                                    <div>
                                        <label className="" htmlFor="name">Name</label>
                                    </div>
                                    <input
                                        readOnly
                                        name="name"
                                        value={values.name}
                                        className={"form-control-plaintext"} />
                                </div>

                                {/* Display Name */}
                                <div
                                    className="form-group">
                                    <div>
                                        <label className="" htmlFor="displayName">Display Name</label>
                                    </div>
                                    <input
                                        name="displayName"
                                        value={values.displayName}
                                        onChange={(e) => {
                                            setFieldTouched(e.target.name);
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        className={getInputClassNames(!!(touched.displayName && errors.displayName))} />
                                    <div className="invalid-feedback">
                                        {errors.displayName && errors.displayName.split("\n").map(error => <>{error}<br /></>)}
                                    </div>
                                </div>

                                {/* Email */}
                                <div
                                    className="form-group">
                                    <div>
                                        <label className="" htmlFor="displayName">Email</label>
                                    </div>
                                    <input
                                        name="email"
                                        value={values.email}
                                        onChange={(e) => {
                                            setFieldTouched(e.target.name);
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        className={getInputClassNames(!!(touched.email && errors.email))} />
                                    <div className="invalid-feedback">
                                        {errors.email && errors.email.split("\n").map(error => <>{error}<br /></>)}
                                    </div>
                                </div>

                                {/* App Identifier */}
                                <div className="form-group">
                                    <div>
                                        <label className="" htmlFor="appIdentifier">App Identifier</label>
                                        <small className="float-right">*Optional</small>
                                    </div>
                                    <input name="appIdentifier" value={values.appIdentifier} onChange={(e) => {
                                        setFieldTouched(e.target.name);
                                        handleChange(e);
                                    }} onBlur={handleBlur}
                                    className={getInputClassNames(!!(touched.appIdentifier && errors.appIdentifier))} />
                                    <div className="invalid-feedback">
                                        {errors.appIdentifier && errors.appIdentifier.split("\n").map(error => <>{error}<br /></>)}
                                    </div>
                                </div>

                                {/* Google Play URI */}
                                <div className="form-group">
                                    <div>
                                        <label className="" htmlFor="googlePlayUri">Google Play URI</label>
                                    </div>
                                    <input name="googlePlayUri" value={values.googlePlayUri} onChange={(e) => {
                                        setFieldTouched(e.target.name);
                                        handleChange(e);
                                    }} onBlur={handleBlur}
                                    className={getInputClassNames(!!(touched.googlePlayUri && errors.googlePlayUri))} />
                                    <div className="invalid-feedback">
                                        {errors.googlePlayUri && errors.googlePlayUri.split("\n").map(error => <>{error}<br /></>)}
                                    </div>
                                </div>

                                {/* App Store URI */}
                                <div className="form-group">
                                    <div>
                                        <label className="" htmlFor="appStoreUri">App Store URI</label>
                                    </div>
                                    <input name="appStoreUri" value={values.appStoreUri} onChange={(e) => {
                                        setFieldTouched(e.target.name);
                                        handleChange(e);
                                    }} onBlur={handleBlur}
                                    className={getInputClassNames(!!(touched.appStoreUri && errors.appStoreUri))} />
                                    <div className="invalid-feedback">
                                        {errors.appStoreUri && errors.appStoreUri.split("\n").map(error => <>{error}<br /></>)}
                                    </div>
                                </div>

                                {/* Notification Title */}
                                <div className="form-group">
                                    <div>
                                        <label className="" htmlFor="notificationTitle">Notification Title</label>
                                    </div>
                                    <input name="notificationTitle" value={values.notificationTitle} onChange={(e) => {
                                        setFieldTouched(e.target.name);
                                        handleChange(e);
                                    }} onBlur={handleBlur}
                                    className={getInputClassNames(!!(touched.notificationTitle && errors.notificationTitle))} />
                                    <div className="invalid-feedback">
                                        {errors.notificationTitle && errors.notificationTitle.split("\n").map(error => <>{error}<br /></>)}
                                    </div>
                                </div>

                                {/* Save */}
                                <div className="form-group mb-0">
                                    <button
                                        type="submit"
                                        onClick={() => handleSubmit()}
                                        className="btn btn-primary"
                                        disabled={!dirty || !isValid || props.updateRequestState === RequestState.InProgress}>
                                        {props.updateRequestState === RequestState.InProgress ? "Saving changes" : "Save changes"}
                                    </button>
                                    <button
                                        onClick={() => props.history.goBack()}
                                        className="btn btn-primary ml-3">Cancel</button>
                                    {props.updateRequestState === RequestState.Failed && <p className="text-danger mt-3">Failed to save changes</p>}
                                    {props.updateRequestState === RequestState.Succeeded && <div className="valid-feedback d-block">Private label data successfully updated</div>}
                                </div>
                            </form>
                        }
                    </Formik>
                </div>
            </div>
        </Page>
    );
};

export default withCommonProps(withAuthProps(connect(
    (state: ApplicationState) => ({ ...state.privateLabelEdit }),
    actionCreators
)(PrivateLabelEditPage as any)));
