import { RequestState } from "../sharedTypes";
import { UserProfileData } from "../UserManagement/EditUser/state";
import { ThermostatMode } from "../ThermostatsList/state";

export interface ThermostatDetailsState {
    requestedThermostatId?: string,
    thermostat?: ThermostatDetails;
    thermostatOwner?: UserProfileData;
    loadThermostatRequestState: RequestState;
}

export enum DayOfWeek {
    Sunday = "Sunday",
    Monday = "Monday",
    Tuesday = "Tuesday",
    Wednesday = "Wednesday",
    Thursday = "Thursday",
    Friday = "Friday",
    Saturday = "Saturday"
}

export enum SensorApplication {
    Unknown = "Unknown",
    Floor = "Floor",
    Room = "Room",
    RoomAndFloorLimit = "RoomAndFloorLimit",
    Sensorless = "Sensorless"
}

export enum FloorType {
    Unknown = "Unknown",
    Laminate = "Laminate",
    Tile = "Tile",
    Wood = "Wood",
    Custom = "Custom",
    Disabled = "Disabled"
}

export enum Screensaver {
    Unknown = "Unknown",
    SetPointTemperature = "SetPointTemperature",
    ActualTemperature = "ActualTemperature",
    Clock = "Clock"
}

export enum ClockFormat {
    Unknown = "Unknown",
    TwelveHour = "TwelveHour",
    TwentyFourHour = "TwentyFourHour"
}

export enum ScrollSpeed {
    Unknown = "Unknown",
    Slower = "Slower",
    Normal = "Normal",
    Faster = "Faster"
}

export enum TemperatureUnit {
    Celsius = "Celsius",
    Fahrenheit = "Fahrenheit"
}

export enum FloorSensorType {
    Type10KOhm = "Type10KOhm",
    Type12KOhm = "Type12KOhm",
    TypeCustom = "TypeCustom"
}

export enum DstType {
    None = "None",
    NorthAmerica = "NorthAmerica",
    Europe = "Europe"
}

export enum BleState {
    NonDiscoverable = 0,
    Connectable = 1,
    Pairing = 2,
    Connected = 3,
    LostConnection = 4
}

export interface ScheduleDay {
    dayOfWeek: DayOfWeek | string | number,
    events: ScheduleEvent[] | null | undefined;
}

export interface ScheduleEvent {
    floorTemperatureCelsius: number;
    roomTemperatureCelsius: number;
    timeOfDaySeconds: number;
    enabled: boolean;
}

export interface ThermostatModeExtended extends ThermostatMode {
    isInStandby: boolean;
}

export interface ThermostatRegulationSettings {
    sensorApplication: SensorApplication;
    floorType: FloorType;
    frostProtectionEnabled: boolean;
    temperatureLimitMax: number;
    temperatureLimitMin: number;
    floorProtectionMax: number;
    floorProtectionMin: number;
    frostProtectionTemperature: number;
    changeTimestamp: Date;
    minSetpoint: number;
    maxSetpoint: number;
}

export interface ThermostatDisplaySettings {
    screenLockEnabled: boolean;
    brightnessNormal: number;
    brightnessScreensaver: number;
    screensaver: Screensaver;
    temperatureUnit: TemperatureUnit;
    displayOn: boolean;
    changeTimestamp: Date;
    timeBetweenScrolling: number;
    clockFormat: ClockFormat;
    textScrollSpeed: ScrollSpeed;
    showMinutesOnly: boolean;
}

export interface ThermostatHeatingSettings {
    floorLoad: number;
    floorSensorType: FloorSensorType;
    isAdaptiveHeatingEnabled: boolean;
    isOpenWindowDetectionEnabled: boolean;
    floorSensorOffset: number;
    roomSensorOffset: number;
    changeTimestamp: Date;
}

export interface ThermostatFloorSensorSettings {
    value15Deg: number;
    value20Deg: number;
    value25Deg: number;
    value30Deg: number;
    changeTimestamp: Date;
}

export interface ThermostatDateTimeSettings {
    dst: DstType;
    timezoneMinutes: number;
    changeTimestamp: Date;
}

export interface ThermostatDateTimeCurrentSettings {
    currentValue: Date;
}

export enum Authentication {
    Unknown = "Unknown",
    Open = "Open",
    Wpa2 = "Wpa2",
}

export interface ThermostatWifiSettings {
    ssid: string;
    authentication: Authentication;
    defaultGateway: string;
    netmask: string;
    ipv4: string;
    ipv6: string;
    dhcp: boolean;
    macWifi: string;
    macBle: string;
    preferedWifiChannel: number;
    numberOfTimesWifiApConnectWasCalled: number;
    bleState: BleState;
    changeTimestamp: Date;
}

export interface ThermostatSettings {
    regulation: ThermostatRegulationSettings;
    display: ThermostatDisplaySettings;
    heating: ThermostatHeatingSettings;
    floorSensor: ThermostatFloorSensorSettings;
    dateTime: ThermostatDateTimeSettings;
    dateTimeCurrent: ThermostatDateTimeCurrentSettings;
    wifi: ThermostatWifiSettings;
}

export interface ThermostatWarrantyInfo {
    totalUptimeS: number;
    relayActiveS: number;
    relayClicks: number;
    overheatTimeS: number;
    maxInternalTemp: number;
    minInternalTemp: number;
    gfciCount: number;
    secSinceLastGfci: number;
}

export interface ThermostatDeviceInformation {
    thermostatSoftwareVersion: string;
    wifiSoftwareVersion: string;
    thermostatHardwareVersion: string;
    warrantyInfo: ThermostatWarrantyInfo;
}

export interface ThermostatStateExtended extends ThermostatState {
    latestChange?: Date;
    lastStateReportedAt?: Date;
}

export interface ThermostatState {
    isOnline: boolean;
    isAwayActive: boolean;
    roomTemperature?: number;
    roomTemperatureFahrenheit?: number;
    floorTemperature?: number;
    floorTemperatureFahrenheit?: number;
    isAdaptiveHeatingActive: boolean;
    isOpenWindowDetected: boolean;
    isHeatRelayActive: boolean;
    errorFlags: number;
    originalErrorFlags: number;
}

export interface ThermostatScheduleData {
    scheduleId: string;
    scheduleName: string;
    nextEventStartTime?: string;
    nextEventDayOfWeek?: DayOfWeek;
    nextEventSetPoint?: number;
    currentSetPoint: number;
}

export interface ThermostatDetails {
    id: string;
    serialNumber: string;
    name: string;
    createdDate: Date;
    zoneId: string;
    userId?: string;
    privateLabelId: string;
    internalDistributorId: string;
    distributorId: string;
    buildingId: string;
    zoneName: string;
    buildingName: string;
    mode: ThermostatModeExtended;
    state: ThermostatStateExtended;
    settings: ThermostatSettings;
    deviceInformation: ThermostatDeviceInformation;
    scheduleData: ThermostatScheduleData;
}
